import React from "react"
import { Link } from "gatsby"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { splitText } from "@utils"

const PrivacyPolicyPage = ({ data }) => {
  const { themeColor } = usePageSetup({ themeColor: "bg-pink-light", displayGeneralForm: false })

  return (
    <Layout>
      <Seo title="Privacy Policy" description="" />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1
              data-aos="stagger"
              className="text-headline"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": "Privacy policy.",
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <main>
        <section className="secondary py-clamp-18-35">
          <div className="lg:grid container grid-cols-12">
            <div className="col-start-2 xl:col-start-3 col-span-9">
              <p>
                This Privacy Policy applies to the <Link to={"/"}>www.jkdesign.com</Link> website and explains the types
                of information we collect from you and how we use the collected information. JK reserves the right to
                make changes to this Privacy Policy and such changes will become effective when posted at{" "}
                <Link to={"/privacy-policy"}>www.jkdesign.com/privacy-policy</Link>. Therefore, it is recommended that
                you check this Privacy Policy on a regular basis. Your continued access and use of{" "}
                <Link to={"/"}>www.jkdesign.com</Link> acknowledges that you understand this Privacy Policy and accept
                its terms and con
              </p>
              <h3 className="text-clamp-26-32 text-salmon mt-clamp-14-16">Types of information collected</h3>
              <p className="mt-9">
                JK does not collect any personal information about you such as your name, mailing, or e­mail address
                except when such information is voluntarily provided by you to <Link to={"/"}>www.jkdesign.com</Link>{" "}
                through this website. Whether you provide any information is entirely up to you.
              </p>
              <p>
                JK does collect non-personally identifiable aggregate information about visitors when pages on
                <Link to={"/"}>www.jkdesign.com</Link> are requested through a Web browser. This information is used for
                analytical and statistical purposes and includes information such as a visitor’s host name, internet
                protocol (IP) address, browser type and version, date and time of the visit, the page from which the
                visitor accessed the current page, the request made by the visitor, volume, traffic pattern, and related
                site information. JK may use this information to tailor your online experience on{" "}
                <Link to={"/"}>www.jkdesign.com</Link>.
              </p>
              <h3 className="text-clamp-26-32 text-salmon mt-9">Cookies</h3>
              <p>
                JK may utilize a standard technology called “cookies” to collect information about your use of
                <Link to={"/"}>www.jkdesign.com</Link>. A “cookie” is a text file that is sent to a browser from a
                website and stored on a computer’s hard disk. Cookies record information about a visitor to a website,
                the pages that were visited by the visitor, the date of the visit, and any preferences that may have
                been set while the visitor was browsing the site. This information may be used by JK, now or in the
                future, to control your access to certain features, customization of content, or to analyze your
                activity and behavior on <Link to={"/"}>www.jkdesign.com</Link>. Cookies cannot retrieve any other data
                from your hard drive nor obtain your e-mail address nor read cookies that were created by other
                websites. The browser only allows the website that created the cookie the ability to access it.
              </p>
              <p>
                If you are concerned about the use of cookies, your Web browser’s options may be set to notify you when
                a cookie is saved to your computer or to disallow cookies. Prohibiting the use of cookies may restrict
                your access to certain types of content or features.
              </p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default PrivacyPolicyPage
